<template>
  <b-button-group size="sm">
    <b-button
      v-if="view_delete"
      @click="delete_item(item)"
      variant="outline-danger"
      v-b-tooltip.hover.v-danger
      title="Remover"
    >
      <b-icon icon="trash" />
    </b-button>
    <b-button
      v-if="view_edit"
      :to="edit_to_converted"
      :variant="edit_variant"
      v-b-tooltip.hover="editVariantToTooltip"
      :title="edit_text"
    >
      <b-icon icon="pencil-square" />
    </b-button>
    <b-button
      v-if="view_show"
      :to="view_to_converted"
      :variant="view_variant"
      v-b-tooltip.hover="viewVariantToTooltip"
      :title="view_text"
    >
      <b-icon icon="eye" />
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    view_show: {
      default: true,
      type: Boolean
    },
    view_edit: {
      default: true,
      type: Boolean
    },
    view_delete: {
      default: true,
      type: Boolean
    },
    view_text: {
      default: "Visualizar",
      type: String
    },
    view_variant: {
      default: "outline-primary",
      type: String
    },
    view_to: {
      type: String
    },
    view_params: {
      type: Object,
      default: null
    },

    edit_show: {
      default: true,
      type: Boolean
    },
    edit_text: {
      default: "Editar",
      type: String
    },
    edit_variant: {
      default: "outline-primary",
      type: String
    },
    edit_to: {
      type: String
    },

    edit_params: {
      type: Object,
      default: null
    },

    delete_show: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    view_to_converted() {
      var params = this.view_params ? this.view_params : { id: this.item.id };

      return { name: this.view_to, params: params };
    },
    edit_to_converted() {
      var params = this.edit_params ? this.edit_params : { id: this.item.id };

      return { name: this.edit_to, params: params };
    },
    viewVariantToTooltip() {
      const variant = String(this.view_variant).replace("outline-", "");
      return { variant };
    },
    editVariantToTooltip() {
      const variant = String(this.edit_variant).replace("outline-", "");
      return { variant };
    }
  },
  methods: {
    delete_item(item) {
      this.$bvModal
        .msgBoxConfirm("Essa operação não poderá ser desfeita.", {
          title: "Deseja realmente excluir?",
          okVariant: "danger",
          okTitle: "SIM",
          size: "md",
          buttonSize: "sm",
          cancelVariant: "outline-primary",
          cancelTitle: "NÃO",
          centered: true,
          headerClass: "border-bottom-0 pb-0"
        })
        .then(value => {
          if (value) {
            this.$emit("delete", item);
          }
        });
    }
  }
};
</script>

<style></style>
